export const formatDate = (date: Date) => {
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  //@ts-ignore
  return date.toLocaleString("en-US", options);
};

export const formatDateTime = (isoString: any) => {
  const date = new Date(isoString);

  // Extract individual components
  const month = date.getMonth() + 1; // Months are zero-based
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  // Format hours and determine AM/PM
  const isPM = hours >= 12;
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

  const period = isPM ? "PM" : "AM";

  // Return formatted string
  return `${month}-${day}-${year}, ${formattedHours}:${minutes}:${seconds} ${period}`;
};
