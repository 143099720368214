import { TWarehouse } from "@helpers/validation-schemas/warehouse-and-inventory/warehouse.schema";
import { useGetAllWarehousesQuery } from "@states/warehouse-and-inventory/warehouse.api";
import { TRegion } from "@type-defs/distribution-region/TRegion";
import { TSuccessResponse } from "@type-defs/general/TSuccessResponse";

const useGetAllWarehouses = (args: {
  page?: number;
  limit?: number;
  search?: string;
  stauts?: string | number;
  warehouse_type?: number;
}) => {
  const {
    data: rawData,
    error,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllWarehousesQuery({
    ...args,
  });

  const warehouse = rawData as any as TSuccessResponse<TWarehouse>;

  return {
    warehouseData: warehouse?.data,
    warehouseMessage: warehouse?.message,
    warehouseSuccess: warehouse?.success,
    warehousePaginator: warehouse?.paginator,
    warehouseError: error,
    warehouseLoading: isLoading,
    warehouseRefetch: refetch,
    warehouseFetching: isFetching,
  };
};

export default useGetAllWarehouses;
