import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { STOCK_IMPORT } from "@utils/constants/warehouse-and-inventory/stock-import.constants";
import { LOCAL_STORAGE_KEYS } from "@utils/constants/local-storeage-keys.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const stockImportApi = createApi({
  reducerPath: "stockImportApi",
  tagTypes: ["StockImport"],
  baseQuery: baseQueryWithReauth,
  // baseQuery: fetchBaseQuery({
  //     baseUrl: getBaseUrl(),
  //     prepareHeaders: (headers) => {
  //         // const token = localStorage.getItem('token');
  //         const storedToken = localStorage.getItem(
  //             LOCAL_STORAGE_KEYS.REFRESH_TOKEN_KEY
  //         );
  //         if (storedToken) {
  //             headers.set('Authorization', `Bearer ${storedToken}`);
  //         }
  //         return headers;
  //     },
  // }),
  endpoints: (builder) => ({
    getAllStockImports: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        from_date?: string;
        to_date?: string;
        warehouse_id?: number;
        search?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        from_date,
        to_date,
        warehouse_id,
        search,
      }) => {
        const queryParams = {
          url: STOCK_IMPORT.GET_ALL,
          params: { page, limit },
        };

        if (from_date) {
          //@ts-ignore
          queryParams.params.from_date = from_date;
        }

        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }

        if (to_date) {
          //@ts-ignore
          queryParams.params.to_date = to_date;
        }

        if (warehouse_id) {
          //@ts-ignore
          queryParams.params.warehouse_id = warehouse_id;
        }

        return queryParams;
      },
    }),
    getStockImport: builder.query<any, { id: string | number }>({
      query: ({ id }) => STOCK_IMPORT.GET_ONE + id,
    }),
    createStockImport: builder.mutation({
      query: (body) => ({
        url: STOCK_IMPORT.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateStockImport: builder.mutation({
      query: ({ id, body }) => ({
        url: `${STOCK_IMPORT.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteStockImport: builder.mutation({
      query: (id) => ({
        url: `${STOCK_IMPORT.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllStockImportsQuery,
  useGetStockImportQuery,
  useCreateStockImportMutation,
  useUpdateStockImportMutation,
  useDeleteStockImportMutation,
} = stockImportApi;
