import { LoadingSpinner } from "@components/layouts/LoadingSpinner";
import { selectCurrentToken } from "@states/auth/auth.slice";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import RootPage from "./RootPage";
import { LOCAL_STORAGE_KEYS } from "@utils/constants/local-storeage-keys.constants";

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const storedToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_KEY);
  // console.log('storedToken==>',storedToken)
  const location = useLocation();

  return storedToken ? (
    <Suspense fallback={<LoadingSpinner />}>
      <RootPage />
    </Suspense>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
