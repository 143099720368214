import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { PRODUCT } from "@utils/constants/product.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";
import { LOCAL_STORAGE_KEYS } from "@utils/constants/local-storeage-keys.constants";

export const productApi = createApi({
  reducerPath: "productApi",
  tagTypes: ["Product"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllProducts: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        search?: string;
        product_category_id?: string | number;
      }
    >({
      query: ({ page = 1, limit = 10, search, product_category_id }) => {
        const queryParams = {
          url: PRODUCT.GET_ALL,
          params: { page, limit },
        };

        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }
        if (product_category_id) {
          //@ts-ignore
          queryParams.params.product_category_id = product_category_id;
        }

        return queryParams;
      },
    }),
    getProductsByCategories: builder.mutation<
      [],
      {
        page?: number;
        limit?: number;
        warehouse_id?: any;
        product_category_ids?: any;
      }
    >({
      query: ({ page = 1, limit = 10, warehouse_id, product_category_ids }) => {
        const queryParams = {
          url: "/consignment/contract/product",
          params: { page, limit },
          method: "POST",
          body: {
            warehouse_id,
            product_category_ids,
          },
        };

        return queryParams;
      },
    }),
    getProduct: builder.query<[], { id: string | number }>({
      query: ({ id }) => `${PRODUCT.EDIT}${id}`,
    }),
    getProductByBarcode: builder.query<[], { barcode: string | number }>({
      query: ({ barcode }) => `${PRODUCT.GET_BY_BARCODE}${barcode}`,
    }),
    createProduct: builder.mutation({
      query: (body) => {
        const { product_photo, ...res } = body;

        const formData = new FormData();
        formData.append("product_photo", product_photo[0]);

        for (const [key, value] of Object.entries(res)) {
          //@ts-ignore
          formData.append(key, value);
        }

        return {
          url: PRODUCT.CREATE,
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              LOCAL_STORAGE_KEYS.ACCESS_TOKEN_KEY
            )}`, // Add token here
          },
        };
      },
    }),

    updateProduct: builder.mutation({
      query: ({ id, body }) => ({
        url: `${PRODUCT.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductsByCategoriesMutation,
  useDeleteProductMutation,
  useGetProductQuery,
  useLazyGetProductQuery,
  useGetProductByBarcodeQuery,
  useLazyGetProductByBarcodeQuery,
  useLazyGetAllProductsQuery,
} = productApi;
