import React, { useState } from "react";
import Modal from "react-modal";
import customStyles from "../styles/customStyles";
import DataTable from "react-data-table-component";
import { searchModalChooseColumns } from "@helpers/columns/form/search-modal-choose.columns";

interface SearchModalProps {
  title: string;
  data: unknown[];
  single?: boolean;
  name: string;
  setValue: (name: string, value: unknown) => void;
  columnName: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  idName?: string;
  nameName?: string;
  columns?: {
    name: string;
    columnName: string;
  }[];
  setDisplayValue: any;
  sub_title?: string;
}

const SearchChooseModal: React.FC<SearchModalProps> = ({
  title,
  data,
  single,
  setValue,
  name,
  columnName,
  isOpen,
  setIsOpen,
  idName,
  nameName,
  columns,
  setDisplayValue,
  sub_title,
}) => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  // Filter data based on search input
  const filteredData = data?.filter((item: any) => {
    const searchValue = searchInput.toLowerCase();
    return Object.values(item).some((value: any) =>
      String(value).toLowerCase().includes(searchValue)
    );
  });

  const handleClick = (id: number | string) => {
    setValue(name!, id);
    setIsOpen(false);

    const target: any = data.find((d: any) => {
      //@ts-ignore
      return d[idName] == id;
    });

    if (target) {
      //@ts-ignore
      setDisplayValue(target[nameName]);
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Search Modal"
    >
      <div className="relative h-full">
        <div className="flex justify-end">
          <svg
            onClick={() => setIsOpen(false)}
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            viewBox="0 -960 960 960"
            width="20px"
            fill="#3e3e3e"
            className="cursor-pointer"
          >
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
          </svg>
        </div>
        <div className="py-4 px-14">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-[600] uppercase text-primary-dark">
              {title}
            </h3>
            <div className="flex w-[33%] items-center justify-center">
              <div className="w-full">
                <label className="relative block text-gray-400 focus-within:text-gray-600">
                  <input
                    type="text"
                    value={searchInput}
                    onChange={handleSearch}
                    placeholder="Search..."
                    className="block w-full px-4 py-[0.65rem] text-[0.80rem] text-gray-500 placeholder-gray-400 bg-white rounded shadow-sm border-[0.7px] border-primary-light form-input focus:outline-none"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="currentColor"
                    className="absolute w-6 h-6 transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                  >
                    <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                  </svg>
                </label>
              </div>
            </div>
          </div>
          {sub_title && <h4 className="my-2">Customer Name: {sub_title}</h4>}
          <div className="h-[410px] overflow-auto">
            <DataTable
              columns={searchModalChooseColumns(
                idName || "id",
                //@ts-ignore
                columns,
                handleClick
              )}
              data={filteredData} // Use filtered data here
              striped
              pagination
              customStyles={{
                rows: {
                  style: {
                    minWidth: "200px",
                  },
                },
                header: {
                  style: {
                    minWidth: "200px",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SearchChooseModal;
