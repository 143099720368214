import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "../../utils/general/getBaseUrl";
import { WAY_USER_ASSIGN } from "@utils/constants/way-user-assign.constants";

export const wayUserAssignApi = createApi({
  reducerPath: "wayUserAssignApi",
  tagTypes: ["WayUserAssign"],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    getAllWayUserAssigns: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        way_name?: string;
        username?: string;
        user_role_id?: string | number;
      }
    >({
      query: ({ page = 1, limit = 10, way_name, username, user_role_id }) => {
        const queryParams = {
          url: WAY_USER_ASSIGN.GET_ALL,
          params: { page, limit },
        };

        if (way_name) {
          //@ts-ignore
          queryParams.params.way_name = way_name;
        }
        if (username) {
          //@ts-ignore
          queryParams.params.username = username;
        }
        if (user_role_id) {
          //@ts-ignore
          queryParams.params.user_role_id = user_role_id;
        }

        return queryParams;
      },
    }),
    getWayUserAssign: builder.query<any, { user_id: string | number }>({
      query: ({ user_id }) => WAY_USER_ASSIGN.GET_ONE + user_id,
    }),
    getWayUserAssignDetail: builder.query<any, { user_id: string | number }>({
      query: ({ user_id }) => WAY_USER_ASSIGN.GET_DETAIL + user_id,
    }),
    createWayUserAssign: builder.mutation({
      query: (body) => ({
        url: WAY_USER_ASSIGN.CREATE,
        method: "POST",
        body,
      }),
    }),
    updateWayUserAssign: builder.mutation({
      query: ({ id, body }) => ({
        url: `${WAY_USER_ASSIGN.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deleteWayUserAssign: builder.mutation({
      query: ({ user_id }) => ({
        url: `${WAY_USER_ASSIGN.DELETE}${user_id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllWayUserAssignsQuery,
  useCreateWayUserAssignMutation,
  useUpdateWayUserAssignMutation,
  useDeleteWayUserAssignMutation,
  useGetWayUserAssignQuery,
  useLazyGetWayUserAssignDetailQuery,
} = wayUserAssignApi;
