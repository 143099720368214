import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { PURCHASE_ORDER } from "@utils/constants/purchase/purchase-order.constants";
import { baseQueryWithReauth } from "@states/auth/auth.api";

export const purchaseOrderApi = createApi({
  reducerPath: "purchaseOrderApi",
  tagTypes: ["PurchaseOrder"],
  baseQuery: baseQueryWithReauth,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getAllPurchaseOrders: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        product_name?: string;
      }
    >({
      query: ({ page = 1, limit = 10, product_name }) => {
        const queryParams = {
          url: PURCHASE_ORDER.GET_ALL,
          params: { page, limit },
        };

        if (product_name) {
          //@ts-ignore
          queryParams.params.product_name = product_name;
        }

        return queryParams;
      },
    }),
    getPurchaseOrder: builder.query<
      any,
      { purchase_order_id: string | number }
    >({
      query: ({ purchase_order_id }) =>
        `${PURCHASE_ORDER.GET_ONE}${purchase_order_id}`,
    }),
    createPurchaseOrder: builder.mutation({
      query: (body) => ({
        url: PURCHASE_ORDER.CREATE,
        method: "POST",
        body,
      }),
    }),
    updatePurchaseOrder: builder.mutation({
      query: ({ id, body }) => ({
        url: `${PURCHASE_ORDER.UPDATE}${id}`,
        method: "PATCH",
        body,
      }),
    }),
    deletePurchaseOrder: builder.mutation({
      query: (id) => ({
        url: `${PURCHASE_ORDER.DELETE}${id}`,
        method: "DELETE",
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const {
  useGetAllPurchaseOrdersQuery,
  useGetPurchaseOrderQuery,
  useCreatePurchaseOrderMutation,
  useUpdatePurchaseOrderMutation,
  useDeletePurchaseOrderMutation,
} = purchaseOrderApi;
