import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { logOut, setCredentials } from "./auth.slice";
import { LOCAL_STORAGE_KEYS } from "@utils/constants/local-storeage-keys.constants";

export const baseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl(),
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const currentState: any = getState();

    const token =
      currentState.auth.accessToken ||
      localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_KEY);

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const baseQueryWithReauth = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    console.log("sending refresh token...");

    // send the refresh token to get new access token
    const refreshToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.REFRESH_TOKEN_KEY
    );

    const refreshResult = await baseQuery(
      {
        url: "/auth/refresh",
        method: "POST",
        body: {
          refresh_token: refreshToken,
        },
      },
      api,
      extraOptions
    );

    console.log("refreshResult", refreshResult);

    //@ts-ignore
    if (refreshResult?.data?.data) {
      const user =
        api.getState().auth.user ||
        //@ts-ignore
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_KEY));
      const { access_toke: accessToken, refresh_token: refreshToken } =
        //@ts-ignore
        refreshResult.data.data;

      //store the new token
      api.dispatch(
        setCredentials({
          accessToken,
          refreshToken,
          user,
        })
      );

      //retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const authApi = createApi({
  reducerPath: "authApi",
  tagTypes: ["Auth"],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "auth/login",
        method: "POST",
        body,
      }),
    }),
  }),
  keepUnusedDataFor: 0,
});

export const { useLoginMutation } = authApi;
