import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getBaseUrl } from "@utils/general/getBaseUrl";
import { STOCK_BALANCE } from "@utils/constants/warehouse-and-inventory/stock-balance.constants";

export const stockBalanceApi = createApi({
  reducerPath: "stockBalanceApi",
  tagTypes: ["StockBalance"],
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
  }),
  endpoints: (builder) => ({
    getAllStockBalances: builder.query<
      [],
      {
        page?: number;
        limit?: number;
        warehouse_id?: number[] | string[];
        non_warehouse_id?: number[] | string[];
        product_category_id?: number[] | string[];
        product_id?: number[] | string[];
        search?: number | string;
      }
    >({
      query: ({
        page = 1,
        limit = 20,
        warehouse_id,
        non_warehouse_id,
        product_category_id,
        product_id,
        search,
      }) => {
        const queryParams = {
          url: STOCK_BALANCE.GET_ALL,
          params: { page, limit },
        };

        if (warehouse_id) {
          //@ts-ignore
          queryParams.params.warehouse_id = Array.isArray(warehouse_id)
            ? warehouse_id
            : [warehouse_id];
        }

        if (non_warehouse_id) {
          //@ts-ignore
          queryParams.params.non_warehouse_id = Array.isArray(non_warehouse_id)
            ? non_warehouse_id
            : [non_warehouse_id];
        }
        if (product_category_id) {
          //@ts-ignore
          queryParams.params.product_category_id = Array.isArray(
            product_category_id
          )
            ? product_category_id
            : [product_category_id];
        }
        if (product_id) {
          //@ts-ignore
          queryParams.params.product_id = Array.isArray(product_id)
            ? product_id
            : [product_id];
        }
        if (search) {
          //@ts-ignore
          queryParams.params.search = search;
        }

        return queryParams;
      },
    }),
  }),
  keepUnusedDataFor: 0,
});

export const { useGetAllStockBalancesQuery } = stockBalanceApi;
